import React, {useState} from 'react';
import './Footer.css';
import menuicon from '../assets/icons/bubble-dots.png'
import qricon from '../assets/icons/qr-code.png';
import shutdownicon from '../assets/icons/shutdown.png';
import Storage from '../Services/Storage';
import { sendMessage } from '../Services/sendMessage';
import { PushEvents } from '../Services/Notification';
import useStorageKey from '../Hooks/useStorageKey';
import { Device, toStringDevice } from '../Models/Devices';

interface Props {
  username?: string
  qr?: boolean
  navigate?: (screen: string) => void
}

const Footer: React.FC<Props> = ({ qr = false, navigate }) => {
  const [menu, setMenu] = useState<boolean>(false);
  const [username] = useStorageKey('username');
  const [firebaseToken] = useStorageKey('firebaseToken');
  const [linkedDevices] = useStorageKey<Device[]>('linkedDevices');
  const qrData = toStringDevice(username, firebaseToken);

  async function logout() {
    toggleMenu();
    await disconnectDevices();
    await Storage.clear();
    window.location.reload();
  }

  function disconnectDevices() {
    let promises = linkedDevices?.map((device) =>
      sendMessage(device.token, {
        title: 'Muber Notification 🏍️',
        body: `📢 ${username} disconnected from you 💔!`,
        event: PushEvents.DeviceUnlinked,
        device: qrData,
        icon: '/logo512.png',
      })
    ) || [];

    return Promise.allSettled(promises);
  }

  function toggleQR() {
    setMenu(false);
    if (navigate) {
      navigate('connecting');
    }
  }

  function toggleMenu() {
    setMenu(menu ? false : true);
  }

  return <div className="footer">
    <div className="footer-menu">{username} <img className="footer-menu-button" onClick={toggleMenu} src={menuicon} alt="menu" /></div>
    <div className={`footer-menu-container ${menu ? 'active' : ''}`}>
    { qr && navigate && <div className="footer-icon" onClick={toggleQR}> <img src={qricon} alt="QRcode" /> </div> }
        <div className="footer-icon" onClick={logout}> <img src={shutdownicon} alt="logout" /></div>
    </div>
  </div>
}

export default Footer
