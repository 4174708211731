import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage as _onMessage } from "firebase/messaging";

export const firebaseWebPushApiKey = 'BJOuG20021uKcr2sq4vBd1mRqlK6Pp42zk7BrckR2vpm5MtONUeTEbji8lIP_r4UBuLwT9X8U_SL4nX5xG_YXA4';


const firebaseConfig = {
  apiKey: "AIzaSyCB7tPcMRGjpAwStx0F7iGieD9rwJZ5-rg",
  authDomain: "muber-prod.firebaseapp.com",
  projectId: "muber-prod",
  storageBucket: "muber-prod.appspot.com",
  messagingSenderId: "102701618282",
  appId: "1:102701618282:web:741983261eb216281f6613",
  measurementId: "G-V2GSP3EB1S"
};

const app = initializeApp(firebaseConfig);

export const messaging = getMessaging(app);

const eventTarget = new EventTarget();
_onMessage(messaging, (payload) => {
  eventTarget.dispatchEvent(new CustomEvent('on-message', {
    detail: payload
  }));
})

export async function getFirebaseToken(): Promise<string> {
  const registration = await navigator.serviceWorker.getRegistration();

  if (!registration) {
    return Promise.reject(new Error('No service worker registered'));
  }
  
  return getToken(messaging, {
    vapidKey: firebaseWebPushApiKey,
    serviceWorkerRegistration: registration
  })
}



export function onMessage(listener: (payload: any) => void) {
  const handler = (event: Event) => {
    listener((event as CustomEvent).detail);
  };
  eventTarget.addEventListener('on-message', handler, false);

  return () => {
    eventTarget.removeEventListener('on-message', handler, false);
  }
}
