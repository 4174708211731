import { useEffect, useState } from "react"
import Storage from "../Services/Storage";

const useStorageKey = <T=string>(key: string) => {
  const [value, setValue] = useState<T>();

  useEffect(() => {
    Storage.get<T>(key).then(setValue);
  }, [key]);

  const update = async (newValue: T | undefined) => {
    if (newValue === null || newValue === undefined) {
      await Storage.del(key);
    } else {
      await Storage.set(key, newValue);
      setValue(newValue);
    }
  };

  return [value, update] as const;
}


export default useStorageKey
