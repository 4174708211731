import React from 'react';
import logo from './logo.png';
import './App.css';
import ProfileScreen from './Screens/ProfileScreen';
import ConnectingScreen from './Screens/ConnectingScreen';
import MainScreen from './Screens/MainScreen';
import useScreenSwitcher from './Hooks/useScreenSwitcher';
import { onMessage } from './Services/messaging';


// Handle incoming messages. Called when:
// - a message is received while the app has focus
// - the user clicks on an app notification created by a service worker
//   `messaging.onBackgroundMessage` handler.
onMessage((payload: any) => {
  console.log('Message received. ', payload);
  // Update the UI to include the received message.
  appendMessage(payload);

  navigator.serviceWorker?.getRegistration().then(async (registration) => {
    if (registration && !!payload.data) {

      if (!payload.data?.title) return;

      registration.showNotification(payload.data?.title, {
        body: payload.data?.body,
        icon: payload.data?.icon,
        data: {
          sender: payload.data?.sender,
          position: payload.data?.position,
          event: payload.data?.event,
        },
        actions: payload.data?.sender ? [{
          action: 'accept',
          title: 'accept',
          icon: '/icon-done.png'
        }, {
          action: 'reject',
          title: 'reject',
          icon: '/icon-close.png'
        },
        ] : []
      })
    }
  })
});

// Add a message to the messages element.
function appendMessage(payload: any) {
  const messagesElement = document.querySelector('#messages');
  const dataHeaderElement = document.createElement('h5');
  const dataElement = document.createElement('pre');
  dataElement.setAttribute('style','overflow-x:hidden;');
  dataHeaderElement.textContent = 'Received message:';
  dataElement.textContent = JSON.stringify(payload, null, 2);
  messagesElement?.appendChild(dataHeaderElement);
  messagesElement?.appendChild(dataElement);
}


function App() {
  const [currentScreen, jumpToScreen] = useScreenSwitcher([
    'profile',
    'connecting'
  ]);

  return (
    <div className="app">
      <header className="app-header">
        <img src={logo} className="app-logo" alt="logo" />
      </header>

      {currentScreen === 'profile' && <ProfileScreen onComplete={() => jumpToScreen('connecting')} />}
      {currentScreen === 'connecting' && <ConnectingScreen onComplete={() => jumpToScreen('main')}/>}
      {currentScreen === 'main' && <MainScreen navigate={jumpToScreen} />}

      <div id="messaging">
        <div id="messages"/>
      </div>
    </div>
  );
}

export default App;
