import useStorageKey from "./useStorageKey";

const useScreenSwitcher = (screens: string[]) => {
  const [currentScreen = screens[0], jumpToScreen] = useStorageKey('screen');
  
  return [
    currentScreen,
    jumpToScreen
  ] as const;
}

export default useScreenSwitcher
