import React from 'react';
import QRcode from 'react-qr-code';
import './QR.css';


interface Props {
  value?: string;
}

const QR: React.FC<React.PropsWithChildren<Props>> = ({ children, value }) => {
  return <div className="qr-code">
    {value ?
      <QRcode
        title={'Muber'}
        size={256}
        level={'L'}
        style={{ height: "auto", maxWidth: "100%", width: "100%" }}
        value={value} />
      : <div> 🔄 </div>
    } {children}
  </div>
}

export default QR
