import React from 'react';
import cx from 'classnames';
import './FlipCard.css'

interface Props {
  className: string,
  cardType: string,
  style: any,
  elementType: any,
  animationDuration: number
}

const Card: React.FC<React.PropsWithChildren<Props>> = ({className, cardType, style,  elementType, animationDuration, children, ...rest}) =>{

  const Component = elementType || 'div'
  return <Component
    className={cx('flip-card', `flip-${cardType}`, className)}
    style={{
      ...style,
      transitionDuration: `${(animationDuration / 1000)}s`
    }}
    {...rest}
  >
    {children}
  </Component>
}

export const FrontSide: React.FC<React.PropsWithChildren<any>>  = ({ isFlipped, style, animationDuration, ...props }) => {
  return <Card
    {...props}
    style={style}
    animationDuration={animationDuration}
    cardType="front"
  />
};

export const BackSide: React.FC<React.PropsWithChildren<any>> = ({ isFlipped, style, ...props }) => {
  return <Card
    {...props}
    style={style}
    cardType="back"
  />
};

Card.defaultProps = {
  animationDuration: 600
};
