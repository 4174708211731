
export type Device = {
  username: string;
  token: string;
}

function isValidDevice(data: string): boolean {
  try { return JSON.parse(data).length === 2 }
  catch { return false; }

}

function parseDevice(data: string): Device | undefined {
  if (!isValidDevice(data)) return undefined;

  const [username, token] = JSON.parse(data);
  return { username, token };
}

function toStringDevice (username: string | undefined, token: string | undefined) {
  if (!username || !token) return undefined;

  return JSON.stringify([username, token]);
}

function containsDevice(devices: Devices, device: Device): boolean {
  return devices?.some((element) => element.username === device.username)
}

function findDeviceIndex(devices: Devices, device: Device): number {
  let index = devices.findIndex(d => d.username === device.username && d.token === device.token);
  return index > 0 ? index: 0;
}

function appendDevice(devices: Devices, device: Device): Devices {
  return !containsDevice(devices, device)
    ? (devices ?? []).concat([device])
    : devices;
}

function removeDevice(devices: Devices, deviceToRemove: Device ): Devices {
  return (devices && deviceToRemove) ?
    devices.filter(device => !(device.username === deviceToRemove.username && device.token === deviceToRemove.token))
    : devices;
}

export { parseDevice, appendDevice, removeDevice, toStringDevice, findDeviceIndex }



export type Devices = Device[];
