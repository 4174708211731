import cx from 'classnames';
import { SyntheticEvent, useEffect, useState } from 'react';
import './LongPressButton.css';

const PRESS_DURATION_S = 1;

interface Props {
  onSubmit: () => void
}

const LongPressButton: React.FC<React.PropsWithChildren<Props>> = ({ onSubmit, children }) => {
  const [pressing, setPressing] = useState(false);

  const classNames = cx("long-press-button", {
    "pressed": !!pressing
  });

  useEffect(() => {
    if (pressing) {
      const t = setTimeout(() => {
        setPressing(false);
        onSubmit();
        window.navigator.vibrate(500);
      }, PRESS_DURATION_S * 1000)

      return () => {
        clearTimeout(t);
      }
    }
  }, [onSubmit, pressing])

  const on = (e: SyntheticEvent) => {
    e.stopPropagation();
    setPressing(true);
    window.navigator.vibrate([30, 100, 30]);
  }

  const off = (e: SyntheticEvent) => {
    e.stopPropagation();
    setPressing(false);
    window.navigator.vibrate(0);
  }
  
  return <div
    className={classNames}
    style={{ transitionDuration: pressing ? `${PRESS_DURATION_S}s` : undefined}}

    onContextMenu={e => e.preventDefault()}
    onMouseDown={on}
    onMouseUp={off}
    onTouchStart={on}
    onTouchEnd={off}>
      {children}
  </div>
}

export default LongPressButton
