import * as IDB from 'idb-keyval';

async function get<T=string>(key: string) {
  return IDB.get<T>(key);
}

async function set<T=string>(key: string, value: T) {
  await IDB.set(key, value).catch((e) => console.log("then", e));
}

async function del(key: string){
  await IDB.del(key);
}

async function clear() {
  await IDB.clear();
}


const Storage =  {
  get, set, del, clear
}

export default Storage
