import React, { useEffect } from 'react';
import { sendMessage } from '../Services/sendMessage';
import LongPressButton from '../Components/LongPressButton';
import Footer from '../Components/Footer';
import { Device, Devices, parseDevice, appendDevice, removeDevice, findDeviceIndex } from '../Models/Devices';
import { PushEvents } from '../Services/Notification';
import './MainScreen.css';
import useStorageKey from '../Hooks/useStorageKey';
import { onMessage } from '../Services/messaging';

function getCurrentPosition(): Promise<GeolocationPosition> {
  return new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(
      position => resolve(position),
      error => reject(error),
      {enableHighAccuracy: false}
    )
  })
}

const MainScreen: React.FC<{ navigate: (screen: string) => void }> = ({ navigate }) => {
  const [linkedDevices, setLinkedDevices] = useStorageKey<Device[]>('linkedDevices');
  const [firebaseToken] = useStorageKey('firebaseToken');
  const [username] = useStorageKey('username');
  const [selectedDevice = 0, setSelectedDevice] = useStorageKey<number>('selectedDevice');

  const currentDevice = linkedDevices && linkedDevices[selectedDevice];

 useEffect(() => {
    return onMessage((payload: any) => {
      if (payload.data && payload.data.event === PushEvents.DeviceLinked) {
        let device = parseDevice(payload.data.device);
        device && setLinkedDevices(appendDevice(linkedDevices!, device));
      } else if (payload?.data.event === PushEvents.DeviceUnlinked) {
        let device = parseDevice(payload.data.device);
        if (currentDevice === device) setSelectedDevice(0);
        device && setLinkedDevices(removeDevice(linkedDevices!, device));
      }
    });
 }, [linkedDevices, setLinkedDevices, setSelectedDevice, currentDevice]);

  useEffect(() => {
    if (linkedDevices?.length === 0) navigate('connecting');
  },[linkedDevices, navigate]);

  async function callMuber() {
    let position = '';
    try {
      let pos = await getCurrentPosition();
      const { coords: {latitude, longitude}} = pos;
      position = `${latitude},${longitude}`;
    } catch(err) {
      console.log(err)
    }

    return currentDevice && sendMessage(currentDevice.token, {
      type: 'request',
      title: 'Calling a Muber 🏍️',
      body: `📢 ${username} is requesting you 🆘!`,
      icon: '/logo512.png',
      sender: firebaseToken,
      position
    })
  }

  function toggleDevice(devices: Devices, device: Device) {
    setSelectedDevice(findDeviceIndex(devices, device));
  }

  return <div className="main-screen">
      <div className="main-screen__selector">
        { linkedDevices && currentDevice && linkedDevices.map((device) => {
          return  <RadioButton
                    label={device?.username}
                    onChange={toggleDevice.bind(this, linkedDevices, device)}
                    value={device?.username === currentDevice?.username}/>
          })
        }
      </div>
      <div>
        Hello <b>{username}</b>, You can now call  <b>{currentDevice?.username}</b>.
      </div>
      <div className="main-screen__cta">
        <LongPressButton onSubmit={callMuber}>Muber</LongPressButton>
      </div>
      <Footer qr={true} navigate={navigate}  />
    </div>
}

const RadioButton: React.FC<any> = ({ label, value, onChange }) => {
  return (
    <>
      <input id={label} className="radio-rotate radio" type="radio" checked={value} onChange={onChange} />
      <label htmlFor={label}> </label>
    </>
  );
};

export default MainScreen
