import React, { useState, useEffect, SyntheticEvent } from 'react';
import QrScanner from 'qr-scanner';
import { getFirebaseToken, onMessage } from '../Services/messaging';
import './ConnectingScreen.css';
import { sendMessage } from '../Services/sendMessage';
import { PushEvents } from '../Services/Notification';
import {FrontSide, BackSide} from '../Components/FlipCard';
import Footer from '../Components/Footer';
import QR from '../Components/QR';
import rotateicon from '../assets/icons/qr-code.png';
import scanicon from '../assets/icons/scan-flip.png';
import useStorageKey from '../Hooks/useStorageKey';
import { Device, parseDevice, appendDevice, toStringDevice, findDeviceIndex } from '../Models/Devices';

interface ScannerProps {
  onScan: (data: string) => void
  pause?: boolean
}

interface Props {
  onComplete: () => void
}

const QrCodeScanner: React.FC<ScannerProps> = ({ onScan, pause = false }) => {
  const scanner = React.useRef<QrScanner | null>(null);
  const onScanRef = React.useRef(onScan);

  const calculateScanRegion = React.useCallback(
    (video: HTMLVideoElement): QrScanner.ScanRegion => {
      const { videoHeight, videoWidth } = video;
      return {
        x: 0,
        y: 0,
        width: videoWidth,
        height: videoHeight,
        downScaledWidth: videoWidth,
        downScaledHeight: videoHeight,
      };
    },
    [],
  );
  const startScanner = (node: any) => {
    if (scanner.current) return;
    scanner.current = new QrScanner(
      node,
      result => {
        console.log('decoded qr code:', result);
        onScanRef.current(result.data)
      },
      {
        calculateScanRegion,
        highlightScanRegion: false,
        highlightCodeOutline: true,
        videoTrackConstraints: {
          aspectRatio: 1
        }
    });
    scanner.current.start();
  }

  useEffect(() => {
    onScanRef.current = onScan;
  }, [onScan]);

  useEffect(() => {
    if (scanner.current) {
      pause ? scanner.current.pause() : scanner.current.start()
    }
  }, [pause]);

  useEffect(() => {
    return () => {
      if (scanner.current) {
        scanner.current.stop();
        scanner.current.destroy();
      }
    }
  }, []);

  return <div className="qr-code-scanner">
    <video className="qr-code-scanner-video" ref={(node) => node && startScanner(node)} ></video>
  </div>
}

const ConnectingScreen: React.FC<Props> = ({ onComplete }) => {
  const [firebaseToken, setFirebaseToken] = useStorageKey('firebaseToken');
  const [savedUsername] = useStorageKey('username');
  const [linkedDevices = [], setLinkedDevices] = useStorageKey<Device[]>('linkedDevices');
  const [,setSelectedDevice] = useStorageKey<number>('selectedDevice');

  const [camera, setCamera] = useState<boolean>(false);

  useEffect(() => {
    // Get registration token. Initially this makes a network call, once retrieved
    // subsequent calls to getToken will return from cache.
    getFirebaseToken().then((currentToken: any) => {
      if (currentToken) {
        setFirebaseToken(currentToken);
      } else {
        console.log('No registration token available. Request permission to generate one.');
      }
    }).catch((err: any) => {
      console.log('An error occurred while retrieving token. ', err);
      setFirebaseToken(undefined);
    });
  }, [setFirebaseToken]);

  useEffect(() => {
    return onMessage(async (payload: any) => {
      if (payload.data && payload.data.event === PushEvents.DeviceLinked) {
        const device = parseDevice(payload.data.device);
        if (device) {
          await setLinkedDevices(appendDevice(linkedDevices!, device));
          await setSelectedDevice(findDeviceIndex(linkedDevices!, device));

          onComplete();
        }
      }
    });
  }, [setLinkedDevices, setSelectedDevice, linkedDevices, onComplete]);

  const qrData = toStringDevice(savedUsername, firebaseToken);

  function toggleCamera() {
    setCamera(camera ? false : true);
  }

  async function handleScanned (data: string) {
    let device = parseDevice(data)

    if (!device) return;
    await setLinkedDevices(appendDevice(linkedDevices, device));
    await setSelectedDevice(findDeviceIndex(linkedDevices, device));
    await sendMessage(device.token, {event: PushEvents.DeviceLinked, device: qrData});

    onComplete();
  }

  const showSkipButton = linkedDevices.length > 0
  const handleSkip = (e: SyntheticEvent) => {
    e.preventDefault();
    onComplete();
  }

  return <div className='connecting-container'>
    <div className="connecting-screen-text">
      Hi <b>{savedUsername}</b> 🤙, please connect to start. {showSkipButton && <>
      Or just <a href="/" onClick={handleSkip}>skip</a>.
      </>}
    </div>
    <div className={`card-container ${camera ? 'isActive' : ''}`} onClick={toggleCamera}>
      <FrontSide>
        <QR value={qrData}> <div className="qr-code-scan"> <img src={scanicon} alt="To Scanner" /> Click to scan</div> </QR>
      </FrontSide>

      <BackSide>
        <div className="qr-code-scanner-container">
          {qrData && <QrCodeScanner onScan={handleScanned} pause={!camera} />}
        <div className="qr-code-scan"> <img src={rotateicon} alt="To QR Code" /> Click to QR</div>
        </div>
      </BackSide>
    </div>
    <Footer />
    </div>
}

export default ConnectingScreen
