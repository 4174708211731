import React, { FormEvent, useState } from 'react';
import './UsernameInput.css';
import { useDebounce } from 'use-debounce';

interface Props {
  username?: string
  onSave: (newUsername: string) => void
}

const UserNameInput: React.FC<Props> = ({ username: previousUsername, onSave }) => {
  const [username, setUsername] = useState(previousUsername ?? '');
  const [debouncedUsername] = useDebounce(username, 1500);


  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();
    onSave(username);
  }

  return <div id="username">
    <form onSubmit={handleSubmit}>
      <span className="username_input_before">&#62; </span>
      <div
        ref={node => node && node.focus()}
        id="username_input"
        contentEditable
        onKeyPress={e => e.key === 'Enter' ? handleSubmit(e) : undefined}
        onInput={e => setUsername(e.currentTarget.textContent ?? '')}></div>
      <span className={`username_input_after ${debouncedUsername ? 'active' : ''}`}
        onClick={handleSubmit}>↩</span>
    </form>
  </div>
}

export default UserNameInput
