import React, { SyntheticEvent, useEffect, useState } from 'react'
import UserNameInput from '../Components/UsernameInput';
import useStorageKey from '../Hooks/useStorageKey';


interface Props {
  onComplete: () => void
}

const ProfileScreen: React.FC<Props> = ({ onComplete }) => {
  const [savedUsername, setSavedUsername] = useStorageKey('username');
  const [notificationPermissionGranted, setNotificationPermissionGranted] = useState<boolean | undefined>();

  const helpURL = "https://help.aweber.com/hc/en-us/articles/360051180914-How-do-I-reset-my-browser-permissions-if-Web-Push-Notifications-are-blocked-";

  useEffect(() => {
    const notificationPermission = Notification.permission;
    if (notificationPermission === 'granted') {
      setNotificationPermissionGranted(true);
    } else {
      Notification.requestPermission().then((permission) => {
        setNotificationPermissionGranted(permission === 'granted'? true: false);
      });
    }
  }, [setNotificationPermissionGranted])

  useEffect(() => {
    if (!!savedUsername && !!notificationPermissionGranted) {
      onComplete();
    }
  }, [onComplete, savedUsername, notificationPermissionGranted]);

  const resetUsername = (event: SyntheticEvent) => {
    event.preventDefault();
    setSavedUsername(undefined);
  }

  if (savedUsername === undefined) {
    return <UserNameInput onSave={setSavedUsername} />
  } else {
    return <div>
      <div id="userName">Username saved: {savedUsername} (<button onClick={resetUsername}>reset</button>)!</div>

      {!notificationPermissionGranted && <div id="permission">
        Sorry but Muber needs permission to send push notifications in order to work 🥺, if you still want to use us find how to do it in <a href={helpURL} target="_blank" rel="noreferrer">this tutorial</a>
      </div>}
    </div>
  }
}

export default ProfileScreen
