const firebaseSendURL = 'https://fcm.googleapis.com/fcm/send';
const firebaseCloudMessagingServerKey = 'AAAAF-l-VGo:APA91bF1qw36_YaCAQ_q8nsseiMXnY4_EKuLxvXpx81ahB6MKkdkOLW5vchKtPFG9qMuU-W0CEUwqjvZo8HeqLAwmqlEXxjtzPGeOlDmV2Go5bVHDIwVJaq1xMaSfthWxrSRqMHppntZ';

export function sendMessage(to: string, data?: any) {
  return fetch(
    firebaseSendURL,
    {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `key=${firebaseCloudMessagingServerKey}`
      },
      method: 'POST',
      body: JSON.stringify(
        { "data": data,
          "to" : to,
          "direct_boot_ok" : true
        })
    })
}
